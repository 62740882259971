import $ from 'jquery'

const session = {
}

session.current = profile => {
  $.extend(session, profile)
}

session.$perms = {
  has(id) {
    for (let i = 0; session.perms && i < session.perms.length; i++) {
      if (id === session.perms[i] || session.perms[i].startsWith(id)) {
        return true
      }
    }
    return false
  }
}

session.logout = () => {
  session.account = {}
}

export default function (Vue) {
  Vue.config.globalProperties.session = session
}
