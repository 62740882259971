import $ from 'jquery'
import { ElLoading, ElMessage } from 'element-plus'
import router from './router'

const setup = {}

setup.token = {
  name: 'X-CSRF-Token',
  set: function (request) {
    let text = request.getResponseHeader(setup.token.name)
    text && (setup.token.value = text)
  }
}

$.ajaxSetup({
  beforeSend: function (xhr) {
    setup.token.value && xhr.setRequestHeader(setup.token.name, setup.token.value);
  }
})

export default function (Vue) {
  Vue.config.globalProperties.api = { exchange, fetch, token }
}

export const exchange = (options) => {
  return new Promise((resolve, reject) => {
    fetch(options).then(reply => {
      if (reply.code === 200) {
        resolve(reply.data)
      } else if (reply.code === 401) { // 未授权、未登录
        router.push('/login')
      } else if (reply.code === 403) { // 拒绝访问、无权限查看
        ElMessage({ type: 'error', message: reply.message })
      } else {
        ElMessage({ type: 'error', message: reply.message })
      }
    }).catch(error => {
      if (error.status === 401) {
        router.push('/login')
        return
      }
      ElMessage({ type: 'error', message: error.responseJSON && error.responseJSON.message ? error.responseJSON.message : '服务器异常，请联系技术人员处理' })
      reject(error)
    })
  })
}

export const fetch = (options) => {
  return new Promise((resolve, reject) => {
    const animate = options.animate === undefined || options.animate // 默认有蒙层
    const loading = animate ? ElLoading.service({ lock: true, text: 'Loading', spinner: 'el-icon-loading' }) : undefined
    options.url = process.env.VUE_APP_BASE_API + '/' + options.url
    !options.contentType && (options.contentType = 'application/json')
    !options.dataType && (options.dataType = 'json')
    options.contentType === 'application/json' && options.data && (options.data = JSON.stringify(options.data))
    $.ajax(options).done((reply, status, request) => {
      setup.token.set(request)
      loading && loading.close()
      resolve(reply)
    }).fail(error => {
      loading && loading.close()
      reject(error)
    })
  })
}

export const token = () => {
  return setup.token
}