export default [{
  path: '/',
  redirect: '/layout'
}, {
  path: '/login',
  component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
}, {
  path: '/layout',
  component: () => import(/* webpackChunkName: "layout" */ './views/Layout.vue'),
  children: [{
    path: '/account/profile/passwd',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/profile/Passwd.vue')
  }, {
    path: '/metrics/accounts',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/metrics/AccountLayout.vue')
  }, {
    path: '/metrics/projects',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/metrics/ProjectLayout.vue')
  }, {
    path: '/metrics/commits',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/metrics/CommitLayout.vue')
  }, {
    path: '/metrics/reports',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/metrics/ReportLayout.vue')
  }, {
    path: '/account/accounts',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/account/AccountLayout.vue')
  }, {
    path: '/account/roles',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/account/RoleLayout.vue')
  }, {
    path: '/account/perms',
    component: () => import(/* webpackChunkName: "layout" */ '@/views/account/PermsLayout.vue')
  }]
}]
