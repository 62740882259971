<template>
  <div id="app">
    <router-view v-if="state.ready" />
  </div>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      state: {
        ready: false
      }
    }
  },
  created () {
    this.api.fetch({ url: 'rnd-account/profile/session' }).then(reply => this.profile(reply)).catch(error => this.profile(error.responseJSON))
  },
  methods: {
    profile (reply) {
      if (reply && reply.code === 200) {
        this.session.current(reply.data)
        this.state.ready = true
        return
      }
      if (reply && (reply.code === 401 || reply.code === 403)) {
        this.$route.name !== 'login' && this.$router.push('/login')
        this.state.ready = true
        return
      }
      this.$message({ type: 'error', message: '服务器异常，请联系技术人员处理' })
    }
  }
}
</script>
<style>
body > #app {
  height: 100%;
}
</style>
