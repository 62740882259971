import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import * as ElementPlusIcons from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import './assets/styles.css'
import App from './App.vue'
import router from './plugins/router'
import utils from './plugins/utils'
import session from './plugins/session'
import api from './plugins/api'

const application = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIcons)) {
  application.component(key, component)
}
application.use(ElementPlus)
  .use(router)
  .use(utils)
  .use(session)
  .use(api)
  .mount('#app')
