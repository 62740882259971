import $ from 'jquery'

export default function (Vue) {
  // 定义工具对象
  const _ = {}
  // extend
  _.extend = $.extend
  // grep
  _.grep = (array1, array2, name) => {
    // 无效的 array 判断
    if (!array1 || !array1.length || !array2 || !array2.length) {
      return []
    }
    // 对比 array1, array2 中的属性 name, 值相同的过滤出来并返回: this._.grep(this.roles.result, this.form.ids, 'id')
    if (Array.isArray(array1) && Array.isArray(array2) && name) {
      const array = []
      for (let i = 0; i < array2.length; i++) {
        const offset = _.inArray(array1, name, array2[i])
        offset !== -1 && array.push(array1[offset])
      }
      return array
    }
    // 获取 array1 中的属性 array2 并返回: this._.grep(this.companies.result, ['owner', 'createdBy', 'updatedBy'])
    if (Array.isArray(array1) && Array.isArray(array2)) {
      const cached = {}
      for (let i = 0; i < array1.length; i++) {
        for (let j = 0; j < array2.length; j++) {
          array1[i][array2[j]] && !cached[array1[i][array2[j]]] && (cached[array1[i][array2[j]]] = true)
        }
      }
      const array = []
      for (const key in cached) {
        Object.hasOwnProperty.call(cached, key) && array.push(key)
      }
      return array
    }
    // this._.grep(company.roles, 'id')
    if (Array.isArray(array1) && typeof array2 === 'string') {
      const array = []
      for (let i = 0; i < array1.length; i++) {
        array.push(array1[i][array2])
      }
      return array
    }
    // jquery 的默认方法
    return $.grep(array1, array2, name)
  }
  // inArray
  _.inArray = (array, name, value) => {
    if (Array.isArray(array) && typeof name === 'string') {
      for (let i = 0; i < array.length; i++) {
        if (array[i][name] === value) {
          return i
        }
      }
      return -1
    }
    return $.inArray(array, name, value)
  }
  // each
  _.each = (array1, props, array2, name, callback) => {
    const cached = {}
    for (let i = 0; i < array2.length; i++) {
      cached[array2[i][name]] = array2[i]
    }
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < props.length; j++) {
        cached[array1[i][props[j]]] && callback.call(this, array1[i], props[j], cached[array1[i][props[j]]])
      }
    }
  }
  // join
  _.join = (array, name, separator) => {
    if (name && separator !== undefined) {
      let text = ''
      for (let i = 0; i < array.length; i++) {
        text += array[i][name]
        i + 1 < array.length && (text += separator)
      }
      return text
    }
    return array.join(separator)
  }
  // table
  _.table = {
    cell: {
      format (array, value, id, name) {
        !id && (id = 'id')
        !name && (name = 'name')
        for (let i = 0; i < array.length; i++) {
          if (array[i][id] === value) {
            return array[i][name]
          }
        }
        return value
      }
    }
  }
  // dates
  _.dates = {
    format: (date, fmt) => {
      if (!date) return undefined
      fmt = fmt || 'yyyy-MM-dd HH:mm:ss'
      var o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
      }
      return fmt
    },
    parse: (text, fmt) => {
      if (!text) return undefined
      fmt = fmt || 'yyyy-MM-dd HH:mm:ss'
      const object = { y: 0, M: 1, d: 0, H: 0, h: 0, m: 0, s: 0, S: 0 }
      fmt.replace(/([^yMdHmsS]*?)(([yMdHmsS])\3*)([^yMdHmsS]*?)/g, function (m, $1, $2, $3, $4) {
        text = text.replace(new RegExp($1 + '(\\d{' + $2.length + '})' + $4), function (_m, _$1) {
          object[$3] = parseInt(_$1)
          return ''
        })
        return ''
      })
      object.M-- // 月份是从0开始的，所以要减去1
      var date = new Date(object.y, object.M, object.d, object.H, object.m, object.s)
      if (object.S !== 0) date.setMilliseconds(object.S) // 如果设置了毫秒
      return date
    }
  }
  // concurrent
  _.concurrent = (tasks, executable) => {
    !tasks && (tasks = [])
    return new Promise(resolve => {
      let count = tasks.length
      if (count === 0) resolve()
      for (let i = 0; i < tasks.length; i++) {
        executable(i, () => {
          if (--count <= 0) resolve()
        })
      }
    })
  }
  // 使用原生 html 展示
  _.raws = (text) => {
    if (!text) return
    text = text.replace(/[\n\r]/g,'<br/>')
    text = text.replace(/ /g,'&nbsp;')
    return text
  }
  // 使用一行展示
  _.lines = (text) => {
    if (!text) return
    text = text.replace(/[\n\r]/g,'; ')
    return text
  }
  // 注册工具类
  Vue.config.globalProperties.__ = _
}
